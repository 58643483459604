import { ApolloClient, InMemoryCache } from '@apollo/client';

const URL = "/sitecore/api/graph/tg";
const KEY = "f894ab82-a4b8-4b79-a010-385a53a12d6d";

export const client = new ApolloClient({
  uri: `${URL}?sc_apikey=${KEY}`,
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors',
  },
});