import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider, useLazyQuery, gql } from '@apollo/client';
import { client } from '/Foundation/SitecoreGraphQL/code/client';
import ModalPortal from '/Foundation/React/code/Components/ModalPortal';

const { SitecoreSiteName } = window._THULEDATA;

const QUERY = gql`
  query Get($siteName: String!) {
    navigation(siteName: $siteName, language: "en") {
      languageSelector
    }
  }
`;

function LanguageSelector() {
  const [getData, { loading, data }] = useLazyQuery(QUERY);

  function toggleLanguages(event) {
    event.currentTarget.classList.toggle('opened');
  }

  useEffect(() => {
    if (!data) {
      const triggerButtons = document.querySelectorAll('button[data-bs-target="#marketSelector"]');
      triggerButtons.forEach((item) => {
        item.addEventListener('click', (e) => {
          getData({ variables: { siteName: SitecoreSiteName.toLowerCase() } });
        });
      });
    }
  }, [getData]);

  return (
    <ModalPortal>
      <div className="modal fade" id="marketSelector" tabIndex="-1" aria-labelledby="marketSelector" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header pt-7 pb-9 px-7 pt-lg-9 px-lg-9">
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body thule-container pb-7 pb-lg-9">
              <div className="main-content">
                {data && data.navigation && (
                  <>
                    {data.navigation.languageSelector.map((region) => (
                      <div key={region.Title} className="region-box pb-5 pb-lg-10">
                        <div className="region-title heading4">{region.Title}</div>
                        <div className="column-list-region">
                          {region.Cultures.map((country) => (
                            <div
                              key={country.Title}
                              className="country-box pb-7 pb-lg-5 pe-lg-6"
                              onClick={toggleLanguages}
                            >
                              <div className="country-name">
                                {country.Languages && country.Languages.length == 1 ? (
                                  <a className="set-cookie-on-click paragraph--s" href={country.Languages[0].Path}>
                                    {country.Title}
                                  </a>
                                ) : (
                                  <>
                                    {country.Languages?.map((lang, i) => {
                                      return (
                                        <React.Fragment key={`${country.Title}_${lang.NativeLanguageName}`}>
                                          <a className="set-cookie-on-click paragraph--s" href={lang.Path}>
                                            {country.Title} ({lang.NativeLanguageName})
                                          </a>
                                          {i < country.Languages.length - 1 && <span> — </span>}
                                        </React.Fragment>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
}

const root = createRoot(document.getElementById('REACT_languageSelector'));
root.render(
  <ApolloProvider client={client}>
    <LanguageSelector />
  </ApolloProvider>
);
